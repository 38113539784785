<template>
  <div class="container-fluid artigos">
    <template v-if="artigo != null">
      <div class="artigos_wrapper_img">
        <div class="artigos__title">
          <h1>{{ artigo.titulo }}</h1>
        </div>
      </div>
    <div class="lasDivider"></div>
      <div class="container">
        <div class="class artigos__list">
          <div class="row">
            <div class="col-md-12">
              <div class="artigos__list--content">
                <div class="image">
                  <img
                    :src="`https://admin.lasbrasil.com${artigo.imagem_destaque.url}`"
                  />
                </div>
                <div class="row">
                  <div class="col-md-6 labels">
                    <span
                      class="fab"
                      v-for="(fab, key) in artigo.fabricantes"
                      :key="key"
                      >{{ fab.nome_fabricante }}</span
                    >
                    <span
                      class="cat"
                      v-for="(cat, key) in artigo.categorias"
                      :key="key"
                      >{{ cat.nome_categoria }}</span
                    >
                  </div>
                  <div class="col-md-6 materiais">
                      <span class="materiais__title">Materiais:</span>
                      <span class="materiais__download" v-for="(mat,key) in artigo.upload_material" :key="key"><a :href="`https://admin.lasbrasil.com${mat.url}`" target="_blank">{{mat.name}}</a></span>
                  </div>
                </div>

                <div class="text" v-html="artigo.descricao"></div>

                <div class="video" v-html="artigo.link_video"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getArtigobyid } from "../../../services/artigos.service";
import Chooser from "../../../components/Chooser.vue";
import qs from "qs";
export default {
  components: {
    Chooser,
  },
  props: [],
  data() {
    return {
      artigo: {},
    };
  },
  created() {
    this.queryDB(this.$route.params.id);
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    async queryDB(id) {
      const { data } = await getArtigobyid(`Instrucoes-de-usos/${id}`);
      this.artigo = data;
    },
  },
};
</script>
<style lang="scss" scoped>
.artigos {
  padding-left: 0px !important;
  padding-right: 0px !important;
  .artigos_wrapper_img {
    background-position:bottom center;
       background-image: linear-gradient(90deg,rgba(13,133,66,0.9) 0%,rgba(32,101,135,0.9) 100%),url('../../../assets/images/lasacademy.jpg')!important;
   .artigos__title {
       display:flex;
       height:50vh;
       margin-top:-140px;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0);
      h1 {
        font-size: 40px;
      }
    }
  }
  .artigos__search {
    margin-top: 30px;
  }

  .artigos__list {
    .artigos__list--content {
      max-width:1000px;
      background-color: #fff;
      padding: 20px;
      border-radius: 5px;
      margin-top: 30px;
      padding-left: 40px;
      padding-right: 40px;
      margin-left:auto !important;
      margin-right:auto !important;
      .image {
        img {
          width: 100%;
        }
      }
      .labels {
        margin-top: 10px;
        margin-bottom: 10px;
        span {
          display: inline-block;
          padding: 5px;
          color: #fff;
          &.fab {
            background-color: #00b8b5;
          }
          &.cat {
            background-color: #206587;
          }
        }
      }
      .materiais {
        margin-top: 10px;
        margin-bottom: 10px;
        span {
          display: inline-block;
          padding: 5px;
          color: #fff;
          &.materiais__title {
            font-weight:bold; 
            margin-right:10px;
            color:#17a2b8;
          }
          &.materiais__download {
             background-color:#206587;
             padding-left:20px;
             padding-right:20px;
             color:#fff !important;
             margin-right:5px;
             a{
                color:#fff !important; 
             }
          }
        }
      }
      .text {
        margin-top: 20px;
        .callToaction {
          display: flex;
          justify-content: flex-end;
          padding-right: 30px;

          button {
            width: 150px;
            background-color: #206587 !important;
            color: #fff !important;
            padding: 10px;
          }
        }
      }
      .video {
          margin-top:40px;
      }
    }
  }
}
</style>